import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import Helmet from 'react-helmet'

import { HtmlParser, PostTags, PostSocialShareAsk } from '../../components/common'
// import { MetaData } from '../components/common/meta'
import { Row, Col, Typography, Divider, Tooltip } from 'antd'
const { Title, Paragraph, Text } = Typography
import { CalendarOutlined, TwitterOutlined, ShareAltOutlined } from '@ant-design/icons'
import Prism from 'prismjs'
import Img from 'gatsby-image'

/**
* Single post view (/:slug)
*
* This file renders a single post and loads all the content.
*
*/
const PostPage = ({ post }) => {
    // console.log(post)
    useEffect(() => {
        Prism.highlightAll()
    })

    return (
        <>
            <Row
                justify={`space-around`}
            >
                <Col
                    xs={24}
                    sm={24}
                    md={18}
                    lg={16}
                >
                    { post.frontmatter.featureImage && post.frontmatter.featureImage.src ?
                        <figure>
                            <Img
                                fluid={post.frontmatter.featureImage.src.childImageSharp.fluid}
                                alt={`Featured image for post: ${post.frontmatter.title}`}
                            />
                        </figure> : null }
                </Col>
            </Row>

            <Row
                gutter={[16, 24]}
                justify={`space-around`}
            >
                <Col
                    xs={22}
                    sm={22}
                    md={18}
                    lg={14}
                >
                    <Typography>
                        <Title className="content-title">
                            {post.frontmatter.title}
                        </Title>

                        <Paragraph>
                            <CalendarOutlined style={{ marginRight: `0.4em` }} />
                            <span style={{ marginRight: `2em` }}>{ post.frontmatter.published_at }</span>
                            <PostTags post={post} />
                        </Paragraph>

                        <Divider style={{ marginTop: 0 }} />

                        {/*<Paragraph>*/}
                            {/* The main post content */ }

                        {/*</Paragraph>*/}
                        <HtmlParser html={post.html} />

                    </Typography>
                </Col>
            </Row>

            { post.frontmatter.collectionType != `portfolio` &&
                <PostSocialShareAsk />
            }
        </>
    )
}

PostPage.propTypes = {
    post: PropTypes.object.isRequired,
}

export default PostPage
