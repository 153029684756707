import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { Tag } from 'antd'

const slug = (slug) => `/tag/${slug}`

const PostTags = ({ post }) => {
    function ListTags() {
        if (post.frontmatter.tags) {
            const listItems = post.frontmatter.tags.map((tag) => {
                if (!tag) {
                    return ``
                }
                let color = `default`
                if (tag.slug === `latest`) {
                    color = `purple`
                } else if (tag.slug === `projects`) {
                    color = `blue`
                }
                return (<Link to={slug(tag.slug)} key={tag.slug}><Tag color={color}>{tag.title}</Tag></Link>)
            })
            return listItems
        }
        return ``
    }

    return (
        <ListTags />
    )
}

PostTags.propTypes = {
    post: PropTypes.shape({
        frontmatter: PropTypes.shape({
            tags: PropTypes.arrayOf(
                PropTypes.shape({
                    title: PropTypes.string,
                    slug: PropTypes.string,
                })
            ).isRequired,
        }).isRequired,
    }).isRequired,
}

export default PostTags
