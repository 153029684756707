import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
// import { Link } from 'gatsby'
import { PostCard, PaginationHelper } from '../common'
import { Row, Col } from 'antd'
import { Parallax } from 'rc-scroll-anim'

const PostsRow = ({tag, posts}) => {
    // console.log(`post row`)
    // console.log(posts)

    // const posts = props.posts
    // console.log(posts)

    return (
        <>
            <Row
                gutter={[16, 24]}
                type="flex"
                justify={`flex-start`}
            >
                {posts.map(post => (
                    <Col
                        key={post.fields.slug}
                        xs={24}
                        sm={12}
                        md={8}
                        lg={6}
                        xl={6}
                    >
                        <Parallax
                            animation={[
                                { y: 0 },
                            ]}
                            style={{ transform: `translateY(-20px)` }}
                        >
                            <PostCard post={post} />
                        </Parallax>
                    </Col>
                ))}

            </Row>
        </>
    )
}

// PostsRow.propTypes = {
//     post: PropTypes.shape({
//         slug: PropTypes.string.isRequired,
//         title: PropTypes.string.isRequired,
//         feature_image: PropTypes.string,
//         featured: PropTypes.bool,
//         tags: PropTypes.arrayOf(
//             PropTypes.shape({
//                 name: PropTypes.string,
//             })
//         ).isRequired,
//     }).isRequired,
// }

PostsRow.propTypes = {
    posts: PropTypes.array,
    // data: PropTypes.shape({
    //     allGhostPost: PropTypes.object.isRequired,
    // }).isRequired,
    // location: PropTypes.shape({
    //     pathname: PropTypes.string.isRequired,
    // }).isRequired,
    // pageContext: PropTypes.object,
}

export default PostsRow
