import React from 'react'
import { Row, Col, Result, Button, Form, Input } from 'antd'
import { SmileOutlined, UserOutlined, MailOutlined } from '@ant-design/icons'
const { TextArea } = Input

function encode(data) {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + `=` + encodeURIComponent(data[key]))
        .join(`&`)
}

const NetlifyContactForm = () => {
    const [state, setState] = React.useState({})
    const formName = `contact-5`

    const handleSubmit = (values) => {
        // console.log(`handling submission. v3.8`)
        // console.log(values)
        setState({ show_error_message: false })
        if (values[`bot-field`] === undefined) {
            delete values[`bot-field`]
        }
        // console.log(encode({
        //     'form-name': formName,
        //     ...values,
        // }))

        // e.preventDefault()
        fetch(`/`, {
            method: `POST`,
            headers: { 'Content-Type': `application/x-www-form-urlencoded` },
            body: encode({
                'form-name': formName,
                ...values,
            }),
        })
            .then(() => showSuccess())
            .catch(error => showError(error))
    }

    const showSuccess = () => {
        setState({ show_success_message: true })
    }

    const showError = (error) => {
        // console.log(error)
        setState({ show_error_message: true })

    }

    return (
        <Row
            justify="space-around"
        >
            <Col
                xs={22}
                sm={18}
                md={16}
                lg={8}
            >

                {/*
                    This defines how your form is setup for the Netlify bots.
                    Users will not see or interact with this form.
                */}
                <form
                    name={formName}
                    data-netlify="true"
                    data-netlify-honeypot="bot-field"
                    hidden
                >
                    <input type="text" name="name" />
                    <input type="email" name="email" />
                    <textarea name="message"></textarea>
                </form>

                { state.show_success_message &&
                    <Result
                        icon={<SmileOutlined />}
                        title="Thanks for the message!"
                        extra={`I'll get back to you soon.`}
                    />
                }
                { !state.show_success_message &&

                    <Form
                        name="cf"
                        method="post"
                        onFinish={handleSubmit}
                        layout="vertical"
                    >
                        <Form.Item
                            label="Don't fill this out"
                            className={`hidden`}
                            name="bot-field"
                        >
                            <Input type={`hidden`} />
                        </Form.Item>

                        <Form.Item
                            label="Name"
                            rules={[{ required: true, message: `Please enter your name so I know who is contacting me` }]}
                            name="name"
                        >
                            <Input
                                placeholder="Name"
                                prefix={<UserOutlined className="site-form-item-icon" />}
                            />
                        </Form.Item>

                        <Form.Item
                            label="Email"
                            rules={[{ required: true, type: `email`, message: `Please enter your email so I can get back to you` }]}
                            name="email"
                        >
                            <Input
                                placeholder="Your Email"
                                prefix={<MailOutlined className="site-form-item-icon" />}
                            />
                        </Form.Item>

                        <Form.Item
                            label="Message"
                            rules={[{ required: true, message: `Please enter your message. Otherwise, why are you contacting me?` }]}
                            name="message"
                        >
                            <TextArea
                                placeholder="Your Message"
                                rows={5}
                            />
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" htmlType="submit" disabled={false}>
                                Send
                            </Button>
                        </Form.Item>
                    </Form>
                }

                { state.show_error_message &&
                    <p>There was an error submitting the form. Please try again.</p>
                }
            </Col>
        </Row>
    )
}

export default NetlifyContactForm
