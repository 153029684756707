import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { Link, StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import config from '../../utils/siteConfig'
import { Layout, Menu, Breadcrumb, Row, Col, Tooltip } from 'antd'
const { Header, Content, Footer } = Layout
import { Parallax } from 'rc-scroll-anim'
import { MediumOutlined, TwitterOutlined, ShareAltOutlined } from '@ant-design/icons'

/**
* Main layout component
*
* The Layout component wraps around each page and template.
* It also provides the header, footer as well as the main
* styles, and meta data for each page.
*
*/
const SiteFooter = ({ site }) => {
    // const site = data.allGhostSettings.edges[0].node

    const twitterUrl = site.twitter ? `https://twitter.com/${site.twitter.replace(/^@/, ``)}` : null
    // const mediumUrl = site.medium ? `https://www.medium.com/${site.medium.replace(/^\//, ``)}` : null
    // const devToUrl = site.dev ? `https://www.dev.to/${site.dev.replace(/^\//, ``)}` : null

    // console.log(site)
    return (
        <>
            <Footer
                style={{ background: `var(--color-primary)`, color: `#ffffff` }}
            >
                <Row justify={`space-between`} style={{ marginBottom: `1rem` }}>
                    <Col >
                        <Link to="/"><img className="footer-site-logo" src={site.logo} alt={site.title} width="80" height="32" /></Link>
                        <br /><br />
                        <Tooltip title="RSS - Use this link in your feed reader app">
                            <a href="https://joeczubiak.com/rss" target="_blank" rel="noreferrer" style={{ verticalAlign: `text-bottom` }}>
                                <img alt="RSS feed icon" src="/images/icons/rss.svg" width="14" height="14" />
                            </a>
                        </Tooltip>
                        <a href="https://twitter.com/joeczubiak" rel="noreferrer" target="_blank"><TwitterOutlined /></a>
                        <a href="https://joeczubiak.medium.com" rel="noreferrer" target="_blank"><MediumOutlined /></a>
                        <a href="https://dev.to/joeczubiak" rel="noreferrer" target="_blank">DEV</a>
                        {/* <a href="https://dev.to/joeczubiak" rel="noreferrer" target="_blank">
                            <img src="https://d2fltix0v2e0sb.cloudfront.net/dev-badge.svg" alt="Joe Czubiak's DEV Community Profile" height="30" width="30" />
                        </a> */}

                    </Col>
                    <Col className="footer-nav-links">
                        {site.navigation.map((navItem, i) => {
                            if (navItem.url.match(/^\s?http(s?)/gi)) {
                                return <a href={navItem.url} key={i} target="_blank" rel="noopener noreferrer">{navItem.label}</a>
                            } else {
                                return <Link key={i} to={navItem.url}><span>{navItem.label}</span></Link>
                            }
                        })}
                        <Link to="/stories"><span>Stories</span></Link>
                        <a href="https://data.joeczubiak.com"><span>Data &amp; APIs</span></a>
                    </Col>
                </Row>

                <Row justify={`center`} style={{ marginTop: `1rem` }}>
                    <Col>
                        <span>© {new Date().getFullYear()} &mdash; Handcrafted by Joe in Los Angeles</span>
                    </Col>
                </Row>
            </Footer>
        </>
    )
}

SiteFooter.propTypes = {
    site: PropTypes.object,
}


export default SiteFooter
