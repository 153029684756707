import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { HtmlParser, PostTags } from '../common'
import { Card, Avatar, Row, Col, Divider, Typography } from 'antd'
const { Title, Paragraph, Text } = Typography

const { Meta } = Card

const PostCardFull = ({ post }) => {
    const url = `${post.fields.slug}`

    function PostImage() {
        if (post.frontmatter.featureImage.src) {
            return (<Img
                fluid={post.frontmatter.featureImage.src.childImageSharp.fluid}
                alt={`Featured image for post: ${post.frontmatter.title}`}
            />)
        }
        return null
    }

    return (
        <Card
            // hoverable
            // cover={<PostImage />}
        >
            <Row
                justify="space-around"
                gutter={[16, 12]}
            >
                <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                >
                    <PostImage />
                </Col>
            </Row>
            <Row gutter={[16, 12]}>
                <Col>
                    <PostTags post={post} />
                </Col>
            </Row>
            <Row gutter={[0, 12]}>
                <Col>
                    { post.frontmatter.published_at }
                </Col>
            </Row>
            <Row gutter={[16, 24]}>
                <Col>
                    <Typography>
                        <Link to={url} >
                            <Title level={2} style={{ fontSize: `44px` }}>
                                {post.frontmatter.title}
                            </Title>
                        </Link>
                    </Typography>
                </Col>
            </Row>

            <Row gutter={[16, 24]}>
                <Col>
                    <HtmlParser html={post.html} />
                </Col>
            </Row>

            <Divider type="horizontal" />

            <Row justify="space-between">
                <Col>
                    <Link to={url}>
                    View Post
                    </Link>
                </Col>
            </Row>
        </Card>

    )
}

PostCardFull.propTypes = {
    post: PropTypes.shape({
        timeToRead: PropTypes.number.isRequired,
        html: PropTypes.string.isRequired,
        fields: PropTypes.shape({
            slug: PropTypes.string.isRequired,
        }),
        frontmatter: PropTypes.shape({
            // slug: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            feature_image: PropTypes.string,
            featured: PropTypes.bool,
            localFeatureImage: PropTypes.object,
            tags: PropTypes.arrayOf(
                PropTypes.shape({
                    name: PropTypes.string,
                })
            ),
            published_at: PropTypes.string.isRequired,
            excerpt: PropTypes.string.isRequired,
            author: PropTypes.shape({
                firstName: PropTypes.string.isRequired,
                lastName: PropTypes.string,
                fullName: PropTypes.string,
                image: PropTypes.object,
            }).isRequired,
        }).isRequired,
    }).isRequired,
}

export default PostCardFull
