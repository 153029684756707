module.exports = {
    siteUrl: `https://joeczubiak.com`, // Site domain. Do not include a trailing slash!

    postsPerPage: 16, // Number of posts shown on paginated pages (changing this requires sometimes to delete the cache)

    siteTitleMeta: `Joe Czubiak`, // This allows an alternative site title for meta data for pages.
    siteDescriptionMeta: `Joe is a developer, technologist, and indie hacker amongst many things. He writes about thoughts, projects, and tutorials.`, // This allows an alternative site description for meta data for pages.

    coverImage: `images/social/default-social-share.png`, // default social share image.
    shareImageWidth: 1000, // Change to the width of your default share image
    shareImageHeight: 523, // Change to the height of your default share image

    shortTitle: `Joe`, // Used for App manifest e.g. Mobile Home Screen
    siteIcon: `favicon.png`, // Logo in /static dir used for SEO, RSS, and App manifest
    logo: `images/joe-czubiak-logo.png`, //The primary logo for your brand displayed across your theme, should be transparent and at least 600px x 72px
    backgroundColor: `#e9e9e9`, // Used for Offline Manifest
    themeColor: `#049CE8`, // Used for Offline Manifest

    twitter: `@joeczubiak`, // Twitter handle
}
