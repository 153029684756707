import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { Menu } from 'antd'
import { Row, Col, Button, Form, Input, Typography } from 'antd'
import { UserOutlined, MailOutlined } from '@ant-design/icons'
const { TextArea } = Input
const { Title, Text } = Typography

function encode(data) {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + `=` + encodeURIComponent(data[key]))
        .join(`&`)
}

/**
* NewsletterSubscribeBanner component
*
*
*/
const NewsletterSubscribeBanner = () => {

    const [errorMessage, setErrorMessage] = useState(``)
    const [success, setSuccess] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const formName = `subscribers`

    const handleSubmit = (values) => {
        setIsLoading(true)

        if (isLoading)
            return

        if (values[`bot-field`] === undefined) {
            delete values[`bot-field`]
        } else {
            setErrorMessage(`Error: It looks to me like you're a bot. If you are in fact a human...I'd love if you used the contact form to send me a message and I'll add you manually. Thanks!`)
            return
        }

        fetch(`/`, {
            method: `POST`,
            headers: { 'Content-Type': `application/x-www-form-urlencoded` },
            body: encode({
                'form-name': formName,
                ...values,
            }),
        })
        .then(() => showSuccess())
        .catch(error => showError(error))
    }

    const showSuccess = () => {
        setIsLoading(false)
        setSuccess(true)
        // TODO: Show a success message or navigate to a success page.
        // console.log(`form submitted successfully`)
    }

    const showError = (error) => {
        setIsLoading(false)
        // TODO: Show an error message to the user
        // console.error(`There was an error submitting the form`)
        console.log(error)
        setErrorMessage(`There was an error. Please try again or you can send me a message using the contact form and I'll add you manually.`)
        // There was an error submitting the form. Please try again.
    }
return (
    <>


    <Row
        justify="center"
        style={{ background: `#006eb5`, color: `#ffffff`, paddingTop: `3rem` }}
    >
        <Col
            xs={22}
            sm={18}
            md={16}
            lg={8}
            style={{ textAlign: `center` }}
        >
            <Text style={{ fontSize: `1.7rem`, color: `#ffffff` }} >Get new posts to your inbox</Text>
            <br />
            <Text style={{ fontSize: `1rem`, color: `#ffffff` }}>I&apos;ll send you an email when I publish a new post</Text>
            <br />
            <br />
            {/*
                This defines how your form is setup for the Netlify bots.
                Users will not see or interact with this form.
            */}
        </Col>
    </Row>
    <Row
        justify="center"
        style={{ background: `#006eb5`, color: `#ffffff`, paddingBottom: `3rem` }}
    >
        <Col
            xs={22}
            sm={18}
            md={16}
            lg={12}
            justify="center"
            style={{ textAlign: `center` }}
        >
            <form
                name={formName}
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                hidden
            >
                <input type="email" name="email" />
            </form>
            { !success &&
                <Form
                    name="newsletter"
                    method="post"
                    onFinish={handleSubmit}
                    layout="inline"
                    style={{ justifyContent: `center` }}
                >
                    <Form.Item
                        label=""
                        className={`hidden`}
                        style={{ display: `none` }}
                        name="bot-field"
                    >
                        <Input />
                    </Form.Item>
                    {/*<Form.Item
                        label="Name"
                        rules={[{ required: true, message: `Please enter your name.` }]}
                        name="name"
                    >
                        <Input
                            placeholder="Name"
                            prefix={<UserOutlined className="site-form-item-icon" />}
                        />
                    </Form.Item>*/}

                    <Form.Item
                        label="Email"
                        rules={[{ required: true, type: `email`, message: `Please enter your email.` }]}
                        name="email"
                        className="email-label"
                    >
                        <Input
                            placeholder="Your Email"
                            prefix={<MailOutlined className="site-form-item-icon" />}
                        />
                    </Form.Item>

                    <Form.Item
                        style={{ marginRight: `0` }}
                    >
                        <Button type="primary" htmlType="submit" loading={isLoading}>
                            Subscribe
                        </Button>
                    </Form.Item>
                </Form>
            }

            {success &&
                <Text  style={{ fontSize: `1.5rem`, color: `#ffffff` }}>Thank you for signing up!</Text>
            }

            { errorMessage &&
                <Text style={{ fontSize: `1rem`, color: `#ffffff` }}>{errorMessage}</Text>
            }
        </Col>
    </Row>
    </>
)}

NewsletterSubscribeBanner.defaultProps = {
}

NewsletterSubscribeBanner.propTypes = {
}

export default NewsletterSubscribeBanner
