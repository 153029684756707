import React from 'react'
import PropTypes from 'prop-types'
import { Link, navigate } from 'gatsby'

import { Row, Col, Pagination } from 'antd'

const PaginationHelper = ({ pageContext }) => {
    const { previousPagePath, nextPagePath, humanPageNumber, numberOfPages } = pageContext
    const onChange = (page, pageSize) => {
        const currentPath = typeof window !== `undefined` ? window.location.pathname : ``
        let newPath = currentPath.substr(0, currentPath.lastIndexOf(`/`) + 1)
        const afterLastSlash = currentPath.substr(currentPath.lastIndexOf(`/`) + 1, currentPath.length)

        if (isNaN(afterLastSlash)) {
            // if not a number, then add trailing slash to current path
            newPath = currentPath.replace(/\/?$/, `/`)
        }

        if (page > 1)
            newPath += page

        navigate(newPath)
    }

    return (
        <Row
            gutter={[16, 24]}
            type="flex"
            justify="center"
            align="center"
        >
            <Col
                xs={24}
                style={{ textAlign: `center` }}
            >
                <Pagination
                    onChange={onChange}
                    defaultCurrent={humanPageNumber}
                    total={numberOfPages}
                    pageSize={1}
                    hideOnSinglePage={true}
                />
            </Col>
        </Row>
    )
}

PaginationHelper.propTypes = {
    pageContext: PropTypes.object.isRequired,
}

export default PaginationHelper
