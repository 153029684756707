import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import PortfolioTags from './PortfolioTags'
import Img from 'gatsby-image'
import PortfolioHighlights from "./PortfolioHighlights"
import { Card, Avatar, Row, Col, Typography } from 'antd'

const { Title, Text, Paragraph } = Typography

const PortfolioCard = ({ post }) => {
    const url = `/portfolio${post.fields.slug}`

    function PostImage() {
        if (post.frontmatter.featureImage.src) {
            return (<Img
                fluid={post.frontmatter.featureImage.src.childImageSharp.fluid}
                alt={`Featured image for post: ${post.frontmatter.title}`}
            />)
        }
        return null
    }

    return (
        <Link to={url} className="portfolio-card">
            <Row gutter={[16, 12]} justify="space-between">
                
                <Col
                    xs={24}
                    sm={24}
                    md={12}
                    lg={12} 
                    >
                        <div
                            className="feature-image"
                        >
                            <PostImage />
                        </div>
                </Col>
                <Col
                    xs={24}
                    sm={24}
                    md={12}
                    lg={12} 
                >
                    <Title level="3" className="title">{post.frontmatter.title}</Title>
                    <Paragraph className="subtitle">{post.frontmatter.excerpt}</Paragraph>
                    
                    <PortfolioHighlights post={post} />
                    
                    <Paragraph className="tech-stack-title"><span>Technology Stack</span></Paragraph>
                    <PortfolioTags post={post} />
                </Col>
            </Row>
        </Link>
    )
}

PortfolioCard.propTypes = {
    post: PropTypes.shape({
        timeToRead: PropTypes.number.isRequired,
        fields: PropTypes.shape({
            slug: PropTypes.string.isRequired,
        }),
        frontmatter: PropTypes.shape({
            title: PropTypes.string.isRequired,
            featured: PropTypes.bool,
            featureImage: PropTypes.shape({
                alt: PropTypes.string,
                title: PropTypes.string,
                src: PropTypes.object,
            }),
            tags: PropTypes.arrayOf(
                PropTypes.shape({
                    name: PropTypes.string,
                }),
            ),
            published_at: PropTypes.string.isRequired,
            excerpt: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
}

export default PortfolioCard
