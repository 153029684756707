import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import PostTags from './PostTags'
import Img from 'gatsby-image'
import { Card, Avatar, Row, Col, Divider, Typography } from 'antd'

const { Meta } = Card

const PostCard = ({ post }) => {
    const url = `${post.fields.slug}`

    function PostImage() {
        if (post.frontmatter.featureImage.src) {
            return (<Img
                fluid={post.frontmatter.featureImage.src.childImageSharp.fluid}
                alt={`Featured image for post: ${post.frontmatter.title}`}
            />)
        }
        return null
    }

    return (
        <Link to={url} className="post-card">
            <Card
                bordered={false} 
                cover={<PostImage />}
            >
                <Row gutter={[16, 12]}>
                    <Col>
                        <PostTags post={post} />
                    </Col>
                </Row>
                <Row gutter={[0, 12]}>
                    <Col>
                        <Typography>{ post.frontmatter.published_at }</Typography>
                    </Col>
                </Row>
                <Row gutter={[16, 24]}>
                    <Col>
                        <Meta
                            title={post.frontmatter.title}
                            description={post.frontmatter.excerpt}
                        />
                    </Col>
                </Row>

                <Divider type="horizontal" />

                <Row justify="space-between">
                    <Col>
                        <Meta
                            avatar={
                                <Avatar icon={<Img
                                    fluid={post.frontmatter.author.image.childImageSharp.fluid}
                                    alt={`${post.frontmatter.author.firstName} ${post.frontmatter.author.lastName}`}
                                />} />
                            }
                            title={post.frontmatter.author.fullName}
                        />

                    </Col>
                    <Col>
                        <Typography>{post.timeToRead} min read</Typography>
                    </Col>
                </Row>
            </Card>
        </Link>
    )
}

PostCard.propTypes = {
    post: PropTypes.shape({
        timeToRead: PropTypes.number.isRequired,
        fields: PropTypes.shape({
            slug: PropTypes.string.isRequired,
        }),
        frontmatter: PropTypes.shape({
            title: PropTypes.string.isRequired,
            featured: PropTypes.bool,
            featureImage: PropTypes.shape({
                alt: PropTypes.string,
                title: PropTypes.string,
                src: PropTypes.object,
            }),
            tags: PropTypes.arrayOf(
                PropTypes.shape({
                    name: PropTypes.string,
                }),
            ),
            published_at: PropTypes.string.isRequired,
            excerpt: PropTypes.string.isRequired,
            author: PropTypes.shape({
                firstName: PropTypes.string.isRequired,
                lastName: PropTypes.string,
                fullName: PropTypes.string,
                image: PropTypes.object,
            }).isRequired,
        }).isRequired,
    }).isRequired,
}

export default PostCard
