import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import Helmet from 'react-helmet'

import { HtmlParser, PostTags, PostSocialShareAsk } from '.'
// import { MetaData } from '../components/common/meta'
import { PortfolioTags } from '../common'
import { Row, Col, Typography, Divider, Tooltip } from 'antd'
const { Title, Paragraph, Text } = Typography
import { CalendarOutlined, TwitterOutlined, ShareAltOutlined } from '@ant-design/icons'
import Prism from 'prismjs'
import Img from 'gatsby-image'


import LiveStream from "../../images/icons/live-stream.svg";
import Team from "../../images/icons/team.svg";
import Users from "../../images/icons/users.svg";
import Layout from "../../images/icons/layout.svg";
import Database from "../../images/icons/database.svg";
import MessageSquare from "../../images/icons/message-square.svg";
import Envelope from "../../images/icons/envelope.svg";
import Palette from "../../images/icons/palette.svg";
import ShoppingCart from "../../images/icons/shopping-cart.svg";
import ShoppingFileInvoiceDollar from "../../images/icons/file-invoice-dollar.svg";
import Robot from "../../images/icons/robot.svg";
import Mobile from "../../images/icons/mobile.svg";
import Watch from "../../images/icons/watch.svg";
import FileCode from "../../images/icons/file-code.svg";
import Confluence from "../../images/icons/confluence.svg";

import PortfolioHighlights from "./PortfolioHighlights"
/**
* Single post view (/:slug)
*
* This file renders a single post and loads all the content.
*
*/
const PortfolioPostPage = ({ post }) => {
    // console.log(post)
    useEffect(() => {
        Prism.highlightAll()
    })

    return (
        <>
            <Row gutter={[16, 12]} justify="space-around">
                
                <Col
                    xs={24}
                    sm={24}
                    md={12}
                    lg={12} 
                    >
                        <div
                            className="feature-image"
                        >
                            { post.frontmatter.featureImage && post.frontmatter.featureImage.src ?
                            <figure>
                                <Img
                                    fluid={post.frontmatter.featureImage.src.childImageSharp.fluid}
                                    alt={`Featured image for post: ${post.frontmatter.title}`}
                                />
                            </figure> : null }
                        </div>
                </Col>
                <Col
                    xs={24}
                    sm={24}
                    md={12}
                    lg={12} 
                    style={{marginTop: `2em`}}
                >
                    {/* <Title level="3" className="title">{post.frontmatter.title}</Title>
                    <Paragraph className="subtitle">{post.frontmatter.excerpt}</Paragraph> */}
                    
                    <PortfolioHighlights post={post} />
                    
                    <Paragraph className="tech-stack-title"><span>Technology Stack</span></Paragraph>
                    <PortfolioTags post={post} />
                </Col>
            </Row>


            <Row
                gutter={[16, 24]}
                justify={`space-around`}
            >
                <Col
                    xs={24}
                    sm={24}
                    md={18}
                    lg={16}
                >
                    <Divider style={{ marginTop: 0 }} />
                    <Typography>
                        {/* <Title className="content-title">
                            {post.frontmatter.title}
                        </Title> */}
                        <Title level="1" className="title">{post.frontmatter.title}</Title>
                        <Paragraph className="subtitle">{post.frontmatter.excerpt}</Paragraph>

                        {/*
                        <Paragraph>
                            <CalendarOutlined style={{ marginRight: `0.4em` }} />
                            <span style={{ marginRight: `2em` }}>{ post.frontmatter.published_at }</span>
                             <PostTags post={post} /> 
                        </Paragraph>
                        */}

                        {/*<Paragraph>*/}
                            {/* The main post content */ }

                        {/*</Paragraph>*/}
                        <HtmlParser html={post.html} />

                    </Typography>
                </Col>
            </Row>
        </>
    )
}

PortfolioPostPage.propTypes = {
    post: PropTypes.object.isRequired,
}

export default PortfolioPostPage
