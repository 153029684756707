import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { Link, StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import config from '../../utils/siteConfig'
import { Layout, Menu, Breadcrumb, Row, Col } from 'antd'
const { Header, Content, Footer } = Layout
import { Parallax } from 'rc-scroll-anim'
import { NewsletterSubscribeBanner, SiteFooter } from '../common'

// Styles
// import '../../styles/app.css'
import "../../styles/app.scss"

/**
* Main layout component
*
* The Layout component wraps around each page and template.
* It also provides the header, footer as well as the main
* styles, and meta data for each page.
*
*/
const MainLayout = ({ data, children, bodyClass, isHome, className, hero }) => {
    // const site = data.allGhostSettings.edges[0].node
    const site = {
        title: `Joe`,
        twitter: `joeczubiak`,
        description: `Projects. Thoughts. Tutorials.`,
        logo: `/images/joe-czubiak-logo.svg`,
        navigation: [
            { label: `Home`, url: `/` },
            { label: `The Latest`, url: `/latest` },
            { label: `Projects`, url: `/tag/project` },
            { label: `Contact`, url: `/contact` },
            { label: `Portfolio`, url: `/portfolio` },
            { label: `About`, url: `/about` },
        ],
    }
    const twitterUrl = site.twitter ? `https://twitter.com/${site.twitter.replace(/^@/, ``)}` : null
    // const facebookUrl = site.facebook ? `https://www.facebook.com/${site.facebook.replace(/^\//, ``)}` : null
    const contentPadding = (isHome || hero) ? `0px 1vw` : `64px 1vw`
    const backgroundColor = isHome ? `inherit` : `#fff`

    // console.log(site)
    return (
        <>
            <Helmet>
                <html lang="en-us" />
                {/*<style type="text/css">{`${site.codeinjection_styles}`}</style>
                <body className={bodyClass} />
                */}
                <meta name="google-site-verification" content="qU10pF2K3iKI7UQkuc_n3tZVGZdX3tgvIzDhRxk2Rdo" />
            </Helmet>

            <Layout className={className}>
                <Header style={{ position: `fixed`, zIndex: 2000, width: `100%` }}>
                    <Link to="/">
                        {site.logo ?
                            <img className="site-logo" src={site.logo} alt={site.title} width="78" height="31" />
                            : <Img fixed={data.file.childImageSharp.fixed} alt={site.title} width="78" height="31" />
                        }
                    </Link>
                    <Menu
                        theme="dark"
                        mode="horizontal"
                        defaultSelectedKeys={[`0`]}
                        style={{ lineHeight: `64px` }}
                    >
                        {site.navigation.map((navItem, i) => {
                            if (navItem.url.match(/^\s?http(s?)/gi)) {
                                return <a href={navItem.url} key={i} target="_blank" rel="noopener noreferrer">{navItem.label}</a>
                            } else {
                                return <Menu.Item key={i}><Link to={navItem.url}><span className="nav-text">{navItem.label}</span></Link></Menu.Item>
                            }
                        })}

                        {/* <Menu.Item style={{ float: `none` }} key={`about`}><Link to="/about"><span className="nav-text">About</span></Link></Menu.Item> */}
                    </Menu>
                </Header>
                
                { isHome ?
                    <Content>
                        <Row
                            className={`hero`}
                        >
                            <Col xs={0} sm={24}>
                                {/*<h1>{site.title}</h1>*/}
                                <img src={`/images/joe-czubiak-logo.svg`} alt="Joe Czubiak" width="180" height="72" />
                            </Col>
                            <Col xs={24}>
                                <p>{site.description}</p>
                            </Col>
                        </Row>
                        <Row
                        >
                            <Col xs={24} sm={24} style={{ overflow: `hidden` }}>
                                <Parallax
                                    animation={{ x: `-10%`, playScale: [0, 1.3] }}
                                    style={{ transform: `translateX(-40%)`, zIndex: 1, opacity: 1 }}
                                    className=""
                                >
                                    <img className={`hero-wave`} src={`/images/wave.svg`} alt="" />
                                </Parallax>
                            </Col>
                        </Row>

                    </Content> :
                    null}
                {/* Show optional hero */}
                { hero != null &&
                    <>{ hero }</>
                }
                

                <Content
                    className={className}
                    style={{ padding: contentPadding, marginTop: `1em` }}
                >

                    {children}
                </Content>
                <Row
                    style={{ background: backgroundColor }}
                >
                    <Col xs={24} sm={24} style={{ overflow: `hidden` }}>
                        <Parallax
                            animation={{ x: `-10%`, playScale: [0, 1.3] }}
                            style={{ transform: `translateX(-30%)`, zIndex: 1, opacity: 1 }}
                            className=""
                        >
                            <img className={`footer-wave`} src={`/images/wave-dark.svg`} alt="" />
                        </Parallax>
                    </Col>
                </Row>

                <NewsletterSubscribeBanner />

                <SiteFooter site={site} />

            </Layout>

        </>
    )
}

MainLayout.propTypes = {
    children: PropTypes.node.isRequired,
    bodyClass: PropTypes.string,
    className: PropTypes.string,
    isHome: PropTypes.bool,
    data: PropTypes.object,
}


export default MainLayout
