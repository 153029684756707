import React from 'react'
import { NetlifyContactForm } from '../shortcodes'
import { TutorialContactForm } from '../shortcodes/tutorials'
// import { CovidLANewCasesMap } from '../shortcodes/maps'
// import PropTypes from 'prop-types'
import parse from 'html-react-parser'

const HtmlParser = ({ html }) => {
    const replace = (domNode) => {
        // Replace shortcodes with their react component counterpart
        if (domNode && domNode.name === `sc` && domNode.attribs) {
            if (domNode.attribs.comp === `NetlifyContactForm`) {
                return (
                    <NetlifyContactForm />
                )
            } else if (domNode.attribs.comp === `TutorialContactForm`) {
                return (
                    <TutorialContactForm />
                )
            } else if (domNode.attribs.comp === `CovidLANewCasesMap`) {
                // {/* <CovidLANewCasesMap /> */}
                return (
                    <></>
                )
            }
        }
        // add line number to code blocks
        if (domNode && domNode.name === `pre`) {
            if (domNode.attribs) {
                domNode.attribs.class = `line-numbers`
            }
        }
        return domNode
    }

    return (
        parse(html, { replace })
    )
}
export default HtmlParser
