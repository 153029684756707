import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import Helmet from 'react-helmet'
import { Row, Col, Typography, Tooltip } from 'antd'
const { Paragraph, Text } = Typography
import { TwitterOutlined, ShareAltOutlined } from '@ant-design/icons'


/**
* PostSocialShareAsk
*
* This file renders a social share ask.
*
*/
const PostSocialShareAsk = () => {
    const [ url, setURL ] = useState(``)

    useEffect(() => {
        let u = window.location.href
        // Remove trailing slash.
        // It causes feature image to not show in Twitter
        if (u.endsWith(`/`)) {
            u = u.slice(0, -1)
        }
        setURL(u)
    })

    return (
        <>
            <Row
                gutter={[16, 24]}
                justify={`space-around`}
            >
                <Col
                    xs={24}
                    sm={24}
                    md={18}
                    lg={16}
                    className="social-share"
                >
                    <Typography>
                        <p style={{ fontSize: `1.8em`, color: `#ffffff`, textAlign: `center` }}>
                            Enjoy this post? {/*I&apos;d love to hear about it.*/}
                        </p>
                        <p style={{ fontSize: `1.1em`, color: `#ffffff`, textAlign: `center` }}>
                            Send me a message on <span className="inline-link"><a href="https://twitter.com/joeczubiak" rel="noreferrer" target="_blank" >Twitter</a></span> or <span className="inline-link"><Link to="/contact">email</Link></span> to let me know.
                        </p>

                        <Paragraph
                            style={{ fontSize: `1.1em`, color: `#ffffff`, textAlign: `center` }}
                            copyable={{
                              icon: [<ShareAltOutlined key="share-icon" style={{ color: `#fff`, marginLeft: `0.5em` }} />, <ShareAltOutlined key="share-icon" style={{ marginLeft: `0.5em` }} />],
                              tooltips: [`Click to copy post URL`, `Post URL copied to your clipboard!`],
                              text: `${url}`,
                            }}
                          >
                            <Tooltip title="Share post to Twitter">
                                <a target="_blank" rel="noreferrer" href={`https://twitter.com/intent/tweet?url=${url}`}><TwitterOutlined style={{ color: `#fff`, marginRight: `0.5em` }} /></a>
                            </Tooltip>
                          </Paragraph>

                        {/*Thanks for reading. Reach out to me on Twitter to let me know

                        Real quick: Before you go*/}
                    </Typography>
                </Col>
            </Row>
        </>
    )
}

PostSocialShareAsk.propTypes = {

}

export default PostSocialShareAsk
