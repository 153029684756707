import React from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'antd'
import Android from "../../images/icons/android.svg";
import Angular from "../../images/icons/angular.svg";
import Antdesign from "../../images/icons/antdesign.svg";
import Bootstrap from "../../images/icons/bootstrap.svg";
import Express from "../../images/icons/express.svg";
import Gatsby from "../../images/icons/gatsby.svg";
import Ios from "../../images/icons/ios.svg";
import Java from "../../images/icons/java.svg";
import Laravel from "../../images/icons/laravel.svg";
import Mysql from "../../images/icons/mysql.svg";
import Node from "../../images/icons/node.svg";
import Postgresql from "../../images/icons/postgresql.svg";
import Python from "../../images/icons/python.svg";
import Reacticon from "../../images/icons/reacticon.svg";
import Tailwind from "../../images/icons/tailwindcss.svg";
import Vue from "../../images/icons/vue.svg";
import Wordpress from "../../images/icons/wordpress.svg";

const PortfolioTags = ({ post }) => {
    const getIcon = (tag) => {
        if (!tag) {
            return ``
        }
        let color = `default`
        if (tag.slug === `laravel`) {
            return <Laravel />
        } else if (tag.slug === `vue`) {
            return <Vue />
        } else if (tag.slug === `react`) {
            return <Reacticon />
        } else if (tag.slug === `android`) {
            return <Android />
        } else if (tag.slug === `ios`) {
            return <Ios />
        } else if (tag.slug === `mysql`) {
            return <Mysql />
        } else if (tag.slug === `postgresql`) {
            return <Postgresql />
        } else if (tag.slug === `java`) {
            return <Java />
        } else if (tag.slug === `bootstrap`) {
            return <Bootstrap />
        } else if (tag.slug === `gatsby`) {
            return <Gatsby />
        } else if (tag.slug === `python`) {
            return <Python />
        } else if (tag.slug === `ant-design`) {
            return <Antdesign />
        } else if (tag.slug === `wordpress`) {
            return <Wordpress />
        } else if (tag.slug === `angular`) {
            return <Angular />
        } else if (tag.slug === `node`) {
            return <Node />
        } else if (tag.slug === `express`) {
            return <Express />
        } else if (tag.slug === `tailwindcss`) {
            return <Tailwind />
        }





        return ``
    }
    const tags = post.frontmatter.tags.map((tag) => {

        if (!tag)
            return ``

        return {
            name: tag.title,
            icon: getIcon(tag),
            slug: tag.slug,
        }
    })

    // function ListTags() {
    //     if (post.frontmatter.tags) {
    //         const listItems = post.frontmatter.tags.map((tag) => {

    //             {/*return (<Tag key={tag.slug} color={color}>{tag.title}</Tag>)*/}
    //         })
    //         return listItems
    //     }
    //     return ``
    // }

    return (
        <Row gutter={[16, 12]} justify="center">
            { tags.filter((tag) => tag.icon).map((tag) => {
                return (
                    <Col>
                        <div className={`tech-icon ${tag.slug}` }>
                            { tag.icon }
                            <p>{ tag.name }</p>
                        </div>
                    </Col>
                )
            })}
        </Row>
    )
}

PortfolioTags.propTypes = {
    post: PropTypes.shape({
        frontmatter: PropTypes.shape({
            tags: PropTypes.arrayOf(
                PropTypes.shape({
                    title: PropTypes.string,
                    slug: PropTypes.string,
                })
            ).isRequired,
        }).isRequired,
    }).isRequired,
}

export default PortfolioTags
