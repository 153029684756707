import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import LiveStream from "../../images/icons/live-stream.svg";
import Video from "../../images/icons/video.svg";
import SatelliteDish from "../../images/icons/satellite-dish.svg";
import Team from "../../images/icons/team.svg";
import Users from "../../images/icons/users.svg";
import Layout from "../../images/icons/layout.svg";
import Database from "../../images/icons/database.svg";
import MessageSquare from "../../images/icons/message-square.svg";
import Envelope from "../../images/icons/envelope.svg";
import Palette from "../../images/icons/palette.svg";
import ShoppingCart from "../../images/icons/shopping-cart.svg";
import ShoppingFileInvoiceDollar from "../../images/icons/file-invoice-dollar.svg";
import Robot from "../../images/icons/robot.svg";
import Mobile from "../../images/icons/mobile.svg";
import Watch from "../../images/icons/watch.svg";
import FileCode from "../../images/icons/file-code.svg";
import Confluence from "../../images/icons/confluence.svg";

import { Typography } from 'antd'

const { Title, Text, Paragraph } = Typography


const PortfolioHighlights = ({ post }) => {
    const url = `/portfolio${post.fields.slug}`

    function PostImage() {
        if (post.frontmatter.featureImage.src) {
            return (<Img
                fluid={post.frontmatter.featureImage.src.childImageSharp.fluid}
                alt={`Featured image for post: ${post.frontmatter.title}`}
            />)
        }
        return null
    }

    return (
        <div className="highlights">
            { post.fields.slug == "/auction-king/" &&

                <div>
                    <Paragraph><Layout className="highlight-icon" /> <span className="highlight-text">Fullstack Web Development</span></Paragraph>
                    <Paragraph><Users className="highlight-icon" /> <span className="highlight-text">Managed Team</span></Paragraph>
                    <Paragraph><MessageSquare className="highlight-icon" /> <span className="highlight-text">Realtime Chat</span></Paragraph>
                    <Paragraph><SatelliteDish className="highlight-icon" /> <span className="highlight-text">Realtime video livestream</span></Paragraph>
                </div>
            }

            { post.fields.slug == "/stocktrot/" &&

                <div>
                    <Paragraph><Layout className="highlight-icon" /> <span className="highlight-text">Fullstack Web Development</span></Paragraph>
                    <Paragraph><Database className="highlight-icon" /> <span className="highlight-text">Tens of millions of database records</span></Paragraph>
                    <Paragraph><Envelope className="highlight-icon" /> <span className="highlight-text">Email Alerts</span></Paragraph>
                    <Paragraph><ShoppingFileInvoiceDollar className="highlight-icon" /> <span className="highlight-text">SaaS</span></Paragraph>
                </div>
            }

            { post.fields.slug == "/patient-tracker-v/" &&

                <div>
                    <Paragraph><Layout className="highlight-icon" /> <span className="highlight-text">Fullstack Web Development</span></Paragraph>
                    {/* <Paragraph><Users className="highlight-icon" /> <span className="highlight-text">Managed Team</span></Paragraph> */}
                    <Paragraph><ShoppingFileInvoiceDollar className="highlight-icon" /> <span className="highlight-text">SaaS</span></Paragraph>
                </div>
            }

            { post.fields.slug == "/moladay-web/" &&

                <div>
                    <Paragraph><Layout className="highlight-icon" /> <span className="highlight-text">Fullstack Web Development</span></Paragraph>
                    <Paragraph><Palette className="highlight-icon" /> <span className="highlight-text">Design</span></Paragraph>
                    <Paragraph><Robot className="highlight-icon" /> <span className="highlight-text">Automation</span></Paragraph>
                    <Paragraph><Envelope className="highlight-icon" /> <span className="highlight-text">Branded Emails and Marketing</span></Paragraph>
                </div>
            }
            { post.fields.slug == "/poshcloset-web/" &&

                <div>
                    <Paragraph><Layout className="highlight-icon" /> <span className="highlight-text">Fullstack Web Development</span></Paragraph>
                    <Paragraph><ShoppingCart className="highlight-icon" /> <span className="highlight-text">E-commerce</span></Paragraph>
                    <Paragraph><Users className="highlight-icon" /> <span className="highlight-text">Managed Team</span></Paragraph>
                </div>
            }

            { post.fields.slug == "/pawndash-web/" &&

                <div>
                    <Paragraph><Layout className="highlight-icon" /> <span className="highlight-text">Fullstack Web Development</span></Paragraph>
                    <Paragraph><SatelliteDish className="highlight-icon" /> <span className="highlight-text">Realtime bidding</span></Paragraph>
                    <Paragraph><ShoppingCart className="highlight-icon" /> <span className="highlight-text">E-commerce</span></Paragraph>
                </div>
            }

            { post.fields.slug == "/ponguru-app/" &&

                <div>
                    <Paragraph><Mobile className="highlight-icon" /> <span className="highlight-text">Native Android Development</span></Paragraph>
                    <Paragraph><Watch className="highlight-icon" /> <span className="highlight-text">Wearable Tech</span></Paragraph>
                    <Paragraph><Palette className="highlight-icon" /> <span className="highlight-text">Design</span></Paragraph>
                </div>
            }

            { post.fields.slug == "/let-it-go-app/" &&

                <div>
                    <Paragraph><Mobile className="highlight-icon" /> <span className="highlight-text">Native iOS and Android Development</span></Paragraph>
                    <Paragraph><Palette className="highlight-icon" /> <span className="highlight-text">Design</span></Paragraph>
                </div>
            }

            { post.fields.slug == "/auction-king-app/" &&

                <div>
                    <Paragraph><Users className="highlight-icon" /> <span className="highlight-text">Oversaw Development</span></Paragraph>
                    <Paragraph><Palette className="highlight-icon" /> <span className="highlight-text">Backend API Development</span></Paragraph>
                </div>
            }


            { post.fields.slug == "/huddlebot-confluence-app/" &&

                <div style={{ marginTop: `2em`, marginBottom: `2em` }}>
                    <Paragraph><Confluence className="highlight-icon" /> <span className="highlight-text">Confluence App</span></Paragraph>
                </div>
            }


            { post.fields.slug == "/joe/" &&

                <div>
                    <Paragraph><FileCode className="highlight-icon" /> <span className="highlight-text">Static Site</span></Paragraph>
                    <Paragraph><Layout className="highlight-icon" /> <span className="highlight-text">Serverless</span></Paragraph>
                    <Paragraph><Palette className="highlight-icon" /> <span className="highlight-text">Design</span></Paragraph>
                </div>
            }

            { post.fields.slug == "/easy-uptime/" &&

                <div>
                    <Paragraph><Layout className="highlight-icon" /> <span className="highlight-text">Frontend Only</span></Paragraph>
                    <Paragraph><Palette className="highlight-icon" /> <span className="highlight-text">Design</span></Paragraph>
                </div>
            }

            { post.fields.slug == "/opp/" &&
                <div>
                    <Paragraph><Layout className="highlight-icon" /> <span className="highlight-text">Fullstack Web Development</span></Paragraph>
                    <Paragraph><Video className="highlight-icon" /> <span className="highlight-text">User recorded video intros</span></Paragraph>
                    <Paragraph><Database className="highlight-icon" /> <span className="highlight-text">Database and server design</span></Paragraph>
                </div>
            }
        </div>
    )
}

PortfolioHighlights.propTypes = {
    post: PropTypes.shape({
        timeToRead: PropTypes.number.isRequired,
        fields: PropTypes.shape({
            slug: PropTypes.string.isRequired,
        }),
        frontmatter: PropTypes.shape({
            title: PropTypes.string.isRequired,
            featured: PropTypes.bool,
            featureImage: PropTypes.shape({
                alt: PropTypes.string,
                title: PropTypes.string,
                src: PropTypes.object,
            }),
            tags: PropTypes.arrayOf(
                PropTypes.shape({
                    name: PropTypes.string,
                }),
            ),
            published_at: PropTypes.string.isRequired,
            excerpt: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
}

export default PortfolioHighlights
